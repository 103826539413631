import { Card, Container, Row, Col, Stack, Button, Placeholder, Modal, Form, InputGroup } from 'react-bootstrap'
import React from 'react'
import ReactMarkdown from 'react-markdown'

import 'bootstrap/dist/css/bootstrap.min.css';

const { useState } = React
const API = "https://cloudflare-app.robinsonz.workers.dev"

function TextPost(props) {
  return (
    <Card style={{ width: '36rem' }}>
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Subtitle><em className="text-muted">{props.user}</em></Card.Subtitle>
        <ReactMarkdown className="card-text" style={{ marginBottom: "0" }}>{props.text}</ReactMarkdown>
      </Card.Body>
    </Card>
  )
}

function ImagePost(props) {
  return (
    <Card style={{ width: '36rem' }}>
      <Card.Img variant="top" src={props.img} />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Subtitle><em className="text-muted">{props.user}</em></Card.Subtitle>
      </Card.Body>
    </Card>
  )
}

function PlaceholderPost(props) {
  return (
    <Card style={{ width: '36rem' }}>
      <Card.Body>
        <Placeholder as={Card.Title} animation="glow">
          <Placeholder xs={6} />
        </Placeholder>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
          <Placeholder xs={6} /> <Placeholder xs={8} />
        </Placeholder>
      </Card.Body>
    </Card>
  )
}

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result.split(',')[1]);
    };

    reader.onerror = reject;

    console.log(file)

    reader.readAsDataURL(file);
  })
}

function NewPost(props) {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("")
  const [user, setUser] = useState("")
  const [body, setBody] = useState("")
  const [validated, setValidated] = useState(false)


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onTitleInput = ({ target: { value } }) => setTitle(value)
  const onUserInput = ({ target: { value } }) => setUser(value)
  const onBodyInput = ({ target }) => setBody(props.isText ? target.value : target.files[0])

  const onSubmit = async event => {
    event.preventDefault()

    const form = event.currentTarget

    setValidated(true);
    if (form.checkValidity() === false) {
      return
    }

    let data = props.isText ? body : await readFileAsync(body)

    // send Poast request
    fetch(`${API}/posts`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        title: title,
        user: `@${user}`,
        postType: props.isText ? 'text' : 'img',
        data: data
      })
    }).then(response => {
      setShow(false)
      setTitle("")
      setUser("")
      setBody("")
      props.onSubmit()
    },
      error => console.error(error))
  }

  return (
    <>
      <Button
        className='col-sm-5'
        variant="primary"
        onClick={handleShow}
      >New {props.isText ? "Text" : "Image"} Post</Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create a New {props.isText ? "Text" : "Image"} Post</Modal.Title>
        </Modal.Header>
        <Form noValidate onSubmit={onSubmit} validated={validated}>
          <Modal.Body>

            <Form.Group className="mb-3" controlId="postTitle">
              <Form.Control
                type="text"
                placeholder="Title"
                onChange={onTitleInput}
                value={title}
                required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="postUser">
              <InputGroup hasValidation>
                <InputGroup.Text id="username">@</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Username"
                  aria-describedby="username"
                  onChange={onUserInput}
                  value={user}
                  required />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="postData">
              {props.isText ? (
                <><Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Say something..."
                  onChange={onBodyInput}
                  value={body}
                  aria-describedby="bodyText"
                  required />
                  <Form.Text className="text-muted" id="bodyText">
                    You can use Markdown in body text. Try <strong>**bold**</strong> or <em>*italics.*</em>
                  </Form.Text></>
              ) : (
                <Form.Control
                  type="file"
                  accept=".jpeg"
                  onChange={onBodyInput}
                  required />
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Create
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      posts: [<PlaceholderPost />, <PlaceholderPost />, <PlaceholderPost />],
      lastUpdated: "",
      isLoading: true
    }

    this.refresh = this.refresh.bind(this)
  }

  componentDidMount() {
    this.refresh()
  }

  refresh() {
    console.log("getting new posts")

    this.setState({ isLoading: true })

    fetch(`${API}/posts`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            posts: result.map(post => {
              let comp = null
              switch (post.postType) {
                case "text":
                  comp = <TextPost title={post.title} user={post.user} text={post.data} />
                  break;
                case "img":
                  comp = <ImagePost title={post.title} user={post.user} img={`${API}/img/${post.data}`} />
                  break;
                default:
                  console.error("Unknown post type: " + post.postType)
                  comp = <PlaceholderPost />
              }
              return comp
            }),
            lastUpdated: ("Last updated at " + new Date().toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })),
            isLoading: false
          });
        },
        (error) => {
          console.error("Error fetching posts: ", error)
          this.setState({
            lastUpdated: "Error fetching new results",
            isLoading: false
          });
        }
      )
  }

  render() {
    return (
      <Container style={{ margin: '1rem' }}>
        <Row>
          <Col sm={4}>
            <Stack gap={3}>
              <h1>SocialMediaApp&trade;</h1>
              <NewPost onSubmit={this.refresh} isText={true} />
              <NewPost onSubmit={this.refresh} isText={false} />
              <Button
                className='col-sm-4'
                variant="secondary"
                disabled={this.state.isLoading}
                onClick={this.state.isLoading ? null : this.refresh}
              >{this.state.isLoading ? 'Refreshing...' : 'Refresh'}</Button>
              <p style={{ fontStyle: "italic" }} className="text-muted"><small>{this.state.lastUpdated}</small></p>
            </Stack>
          </Col>
          <Col>
            <Stack gap={3}>
              {this.state.posts}
            </Stack>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default App;